$(document).ready(function () {
  if ($('#single-content').offset()) fixedSocialBar();
  toggleSearchInput();
  toggleMenuIcon();
  fixedHeader();
});

/**
 * This function will make social bar in fixed position in article content area.
 */
function fixedSocialBar() {
  var $social__icon = $('#single-social-fixed'),
    upLimit = $('#single-content').offset().top,
    height = $('#single-content').height();
  $(window).on('scroll', function () {
    if ($(window).scrollTop() > upLimit && $(window).scrollTop() < height) {
      $social__icon.addClass('single__social--fixed');
    }
    if ($(window).scrollTop() < upLimit || $(window).scrollTop() > height) {
      $social__icon.removeClass('single__social--fixed');
    }
  });
}

/**
 * Toggles the search input field on click.
 */
function toggleSearchInput() {
  var $search = $('#search');
  var $field = $('#search-field');
  var $icon = $('#search-icon');

  $icon.on('click', function () {
    $icon.toggleClass('fa-search').toggleClass('fa-times-circle');
    $search.fadeToggle(300, function () {
      $field.is(':visible') ? $field.focus() : $field.focusout();
    });
  });
}

/**
 * Toggles the menu icon on click.
 */
function toggleMenuIcon() {
  $('#offCanvas').on('opened.zf.offcanvas closed.zf.offcanvas', function () {
    $('#menu-icon').toggleClass('fa-bars').toggleClass('fa-times');
  });
}

/**
 * This function will make header in fixed position on scrolling.
 */
function fixedHeader() {
  var $header = $('#header');
  var menuOffset = $('#menu').offset().top;
  var menuHeight = $('#menu').height();
  var totalHeight = menuOffset + menuHeight;

  $(window).on('scroll', function () {
    if ($(window).scrollTop() > totalHeight) {
      $header.addClass('header--sticky');
      $header.slideDown();
    } else if ($(window).scrollTop() < menuHeight) {
      $header.removeClass('header--sticky');
      $header.removeAttr('style');
    }
  });
}
